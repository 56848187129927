<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Content Management
            <v-spacer></v-spacer>
            <add-button
              permission="school-read"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
            >
              Add Content
            </add-button>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.title }}</td>
                <td><img height="50" :src="item.image" alt="" /></td>
                <td class="text-xs-left">{{ item.category }}</td>
                <td class="text-xs-left">{{ item.publish_date || "-" }}</td>
                <td class="text-xs-left">{{ item.expire_date || "-" }}</td>
                <td class="text-xs-left">
                  <v-icon small>open_in_new</v-icon> &nbsp;
                  <a
                    target="_blank"
                    :href="'https://youtube.com/watch?v=' + item.video_link"
                    >{{ item.video_link }}</a
                  >
                </td>
                <td class="text-xs-left">
                  {{ item.short_description || "-" }}
                </td>
                <td class="text-right">
                  <edit-button
                    color="warning"
                    icon="edit"
                    @agree="form.reset(), form.edit(item)"
                    permission="school-update"
                  >
                  </edit-button>
                  <delete-button
                    color="danger"
                    @agree="form.reset(), form.delete(item.id)"
                    permission="school-update"
                  >
                  </delete-button>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="form.dialog" persistent max-width="550px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-form
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
          >
            <v-text-field
              type="search"
              autocomplete="title"
              label="Title *"
              required
              class="pa-0"
              outlined
              dense
              v-model="form.title"
              name="title"
              :error-messages="form.errors.get('title')"
              :rules="[(v) => !!v || 'Title is required']"
            />
            <v-text-field
              autocomplete="videoLink"
              label="Youtube Video"
              class="pa-0"
              outlined
              dense
              v-model="form.video_link"
              name="videolink"
            />
            <v-calendar-field
              id-val="publish_date"
              v-model="form.publish_date"
              label="Publish Date"
            >
            </v-calendar-field>

            <v-calendar-field
              :margin="10"
              id-val="expire_date"
              v-model="form.expire_date"
              label="Expire Date"
            >
            </v-calendar-field>

            <v-flex sm12 class="mt-2">
              <v-select
                v-model="form.category"
                :items="category_no"
                label="Category"
                required
                dense
                outlined
              ></v-select>
            </v-flex>

            <v-textarea
              autocomplete="videoLink"
              label="Short Description"
              class="pa-0 mt-5"
              outlined
              dense
              v-model="form.short_description"
              name="short_description"
              row-height="15"
            ></v-textarea>
            <wysiwyg v-model="form.description" />
            <image-picker
              v-model="form.image"
              uiType="dragger"
              @result="cropImage"
              :compressImage="true"
              :ratio="16 / 6"
              @onRemove="onRemove()"
            >
            </image-picker>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="form.reset()">Close</v-btn>
              <v-btn color="success" text v-if="save_flag" @click="validate()"
                >Save</v-btn
              >
              <v-btn color="success" text v-else>Update</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Mixins from "@/library/Mixins";
import Form from "@/library/Form";

export default {
  mixins: [Mixins],
  data: () => ({
    save_flag: true,
    valid: true,
    lazy: false,
    category_no: [
      "Billing",
      "Academic",
      "Users and Roles",
      "Authentication",
      "Support",
      "Transport",
      "Miscellaneous",
      "Accounting",
      "Students",
      "AIO",
      "ID Card",
      "Template Manager",
      "Examination",
      "Others"
    ],
    form: new Form(
      {
        title: "",
        dialog: false,
        slug: "",
        image: "",
        video_link: "",
        publish_date: "",
        expire_date: "",
        short_description: "",
        description: "",
        category: "",
      },
      "api/content-management"
    ),
    headers: [
      { text: "#", align: "left", value: "sno", width: 5, sortable: false },
      { text: "Title", align: "left", value: "title", sortable: false },
      { text: "Image", align: "left", value: "image", sortable: false },
      { text: "Category", align: "left", value: "category", sortable: false },
      {
        text: "Publish Date",
        align: "left",
        value: "publish_date",
        sortable: false,
      },
      {
        text: "Expire Date",
        align: "left",
        value: "expire_date",
        sortable: false,
      },
      {
        text: "Youtube Link",
        align: "left",
        value: "video_link",
        sortable: false,
      },
      {
        text: "Short Description",
        align: "left",
        value: "short_description",
        sortable: false,
      },
      { text: "Action", align: "right", sortable: false },
    ],
    cropperImg: {
      cropDialog: false,
      imgSrc: "",
      cropImg: "",
      uploadCropped: {},
      name: null,
    },
    footerProps: {},
    pagination: {},
  }),
  watch: {
    pagination: function () {
      this.get();
    },
  },
  methods: {
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    cropImage(data) {
      this.cropperImg.cropImg = data.image;
      this.cropperImg.uploadCropped = data.cropped;
    },
    onRemove() {
      this.form.image = null;
    },
    validate() {
      this.form.image = this.cropperImg.uploadCropped;
      if (this.$refs.form.validate()) {
        this.form
          .store()
          .then((res) => {})
          .catch((err) => {});
      }
    },
  },
};
</script>
